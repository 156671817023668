import Categories from "./Categories";
import Divider from "./Divider";
import Pagination from "./Pagination";
import Product from "./Product";
import { useSalesrepUuid } from "../utils/cookieUtils";

export default function Products({ products, title }) {
  const salesrepUuid = useSalesrepUuid();

  return (
    <div className="flex flex-col gap-4 max-w-[70rem]">
      <Divider text={title} />
      {/* <div className="mb-4"> */}
      {/*   <Categories /> */}
      {/* </div> */}
      {products.map((product) => (
        <Product
          key={product.id}
          product={product}
          salesrepUuid={salesrepUuid}
        />
      ))}
      {/* <div className="flex flex-col items-center justify-end mt-2"> */}
      {/*   <Pagination /> */}
      {/* </div> */}
    </div>
  );
}
