import { FaMarker } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaSmile } from "react-icons/fa";
import Button from "./Button";
import Divider from "./Divider";

export default function EPartner() {
  return (
    <div className="flex flex-col gap-4">
      <div
        className="pb-20 p-20 flex flex-col gap-2 h-full bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1.0), rgba(0, 0, 0, 0.1)), url(https://www.investormarket.de/wp-content/uploads/2021/01/3.png)`,
        }}
      >
        <div className="flex flex-col md:gap-4 items-center mt-20">
          <div className="primary-color md:text-6xl text-xl font-bold text-[#F7941E]">
            ePartnerprogramm
          </div>
          <div className="secondary-color md:text-4xl text-center text-[#165272]">
            Deine attraktive Vergütung als Affiliate Partner
          </div>
        </div>
      </div>
      <div className="flex flex-col  items-center w-full">
        <div className="flex flex-col gap-4 ml-10 mr-10 mt-4 max-w-[65rem]">
          <div className="flex flex-col gap-1">
            <div className="font-bold">Wer kann Affiliate ePartner werden?</div>
            <div>
              Mit dem ePartnerprogramm von Investormarket können Privatpersonen,
              Anlageberater und Online Marketer, Tippgeber (Affiliates) für die
              Investorencommunity zu einer attraktiven Provision werden.
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-bold">
              Wie funktioniert das Affiliate ePartnerprogramm?
            </div>
            <div>
              Mit unserem Affiliate ePartnerprogramm wirst Du Tippgeber
              (Affiliate) für die gelisteten Anbieter (Advertiser) auf
              Investormarket. Das Provisionsmodell wird Dir von Investormarket
              vorgegeben. Die anfallende Provision wird jedoch vom Advertiser an
              dich abgerechnet. Unser ePartnerprogramm hilft Dir dabei in Deinem
              Affiliate Partner Cockpit einen Überblick über Deine Provisionen
              zu behalten.
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-bold">Deine Vorteile auf einen Blick:</div>
            <ul>
              <li>- Eine Anmeldung, um mehrere Anbieter zu bewerben,</li>
              <li>- Attraktive Vergütung für die Werbung neuer Anleger,</li>
              <li>
                - Erweiterte Vergütung für die Werbung weiterer
                Affiliatepartner,
              </li>
              <li>
                - Höhere Transparenz durch gemeinschaftliche
                Abrechnungserstellung,
              </li>
              <li>
                - Höherer Status bei Anbietern durch das gemeinschaftliche
                ePartnerprogramm,
              </li>
              <li>- Affiliatepartner Cockpit mit umfangreicher Statistik</li>
            </ul>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-bold">Wie geht es dann weiter?</div>
            <div>
              Platziere Deine Werbelinks auf Deiner Website, Deinem Blog, poste
              sie in den sozialen Medien oder versende sie per E-Mail. Lass
              Deiner Kreativität freien Lauf! Die von Dir geworbenen
              Interessenten kommen über die Werbelinks auf investormarket.de.
              Der Interessent registriert sich und kann über die im Anlagen
              Marktplatz und Magazin gelisteten Anbieter (Advertiser) für
              digitale Angebote von aufsichtsrechtlich regulierten
              Kapitalanlagen investieren oder selbst Affiliate ePartner werden.
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="font-bold">
              Wie profitiere ich als Affiliate ePartner?
            </div>
            <div>
              Du erhältst eine attraktive Provision für jeden geworbenen
              Anleger. Die Provision und das Provisionsmodell ist von
              investormarket.de festgelegt und standardisiert. Detailliertere
              Informationen kannst Du in den Sonderbedingungen für Affiliates
              einsehen. Über das ePartner Cockpit sind die Konditionen ebenfalls
              hinterlegt und Du kannst Dir jederzeit den Stand Deiner Provision
              anschauen.
            </div>
          </div>
          <div>
            Es lohnt sich! Melde dich zum Affiliate ePartnerprogramm an!
          </div>
        </div>
      </div>
      <div className="bg-gray-100 w-full flex flex-col justify-center items-center mt-4">
        <Divider text="So funktioniert's" />
        <div className="pl-8 pr-8 pb-8 pt-4 md:grid grid-cols-4 gap-4 flex flex-col gap-8 max-w-7xl justify-start md:items-start items-center">
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaMarker size="3rem" color="#165272" />
            </div>
            <div className="font-bold">1. Anmelden</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaCogs size="3rem" color="#165272" />
            </div>
            <div className="font-bold">2. Werbemittel platzieren</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaUsers size="3rem" color="#165272" />
            </div>
            <div className="font-bold">3. Interessenten werben</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaSmile size="3rem" color="#165272" />
            </div>
            <div className="font-bold">4. Provision kassieren</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col  items-center w-full">
        <div className="flex flex-col gap-4 ml-10 mr-10 mt-4 max-w-[65rem] mb-4">
          Du hast weitere Fragen zu unserem ePartnerprogramm? Dann schreibe uns
          eine E-Mail und wir werden uns umgehend melden.
        </div>
        <Button
          onClick={() => {
            window.open("https://investormarket.brokeraccount.eu/*/signup");
          }}
        >
          Jetzt registrieren und ePartner werden
        </Button>
      </div>
    </div>
  );
}
