import Button from "./Button";

export default function Product({ product, salesrepUuid }) {
  return (
    <div className="md:flex gap-4 border-2 justify-center items-center bg-slate-100 pl-2">
      <img className="img" src={product.img1} />
      <div className="p-4 flex flex-col justify-between gap-4 items-center md:items-start">
        <div className="text-lg font-bold">{product.name}</div>
        <div>{product.marketplaceDescription}</div>
        <div className="w-full md:flex md:flex-row flex-col items-end justify-center md:justify-between gap-2">
          <div className="grow-0 max-w-[37rem]">
            <div className="font-bold text-xs">Risikohinweis</div>
            <div className="text-xs">
              Der Erwerb dieses Wertpapiers ist mit erheblichen Risiken
              verbunden und kann zum vollständigen Verlust des eingesetzten
              Vermögens führen.
            </div>
          </div>
          <div className="grow-4 mt-2 md:mt-0">
            <Button
              onClick={() => {
                window.open(
                  `https://${product.landingPageDomain}?salesrep_uuid=${salesrepUuid}`
                );
              }}
            >
              zum Anbieter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
