import Hero from "./Hero";
import Products from "./Products";
import { getProducts } from "../api/productsApi";
import { useEffect, useState } from "react";

export default function Home() {
  const [zeichnableProducts, setZeichnableProducts] = useState();
  const [notZeichnableProducts, setNotZeichnableProducts] = useState();

  useEffect(() => {
    _getProducts();

    async function _getProducts() {
      const res = await getProducts();
      setZeichnableProducts(res.zeichnableProducts);
      setNotZeichnableProducts(res.notZeichnableProducts);
    }
  }, []);

  return (
    <div className="flex flex-col gap-16 justify-center items-center p-4 ">
      <Hero />
      {zeichnableProducts && zeichnableProducts.length > 0 && (
        <Products title="Aktuelle Anlagen" products={zeichnableProducts} />
      )}
      {notZeichnableProducts && notZeichnableProducts.length > 0 && (
        <Products title="In Kürze buchbar" products={notZeichnableProducts} />
      )}
    </div>
  );
}
