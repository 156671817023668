import Divider from "./Divider";

export default function Hero() {
  return (
    <div className="flex flex-col items-center gap-4 max-w-[70rem]">
      <Divider text="Dein Anlagen Marktplatz" />
      <div>
        Auf <b>investview.de</b> findest Du eine Vielzahl an interessanten
        Investitionsprojekten in den Bereichen Start-Ups, Energie, Immobilien
        und KMU's. Mit nur einem Klick auf die entsprechende Werbeanzeige
        gelangst Du auf die Projektseite des jeweiligen Anbieters und kannst
        Dich über das Projekt informieren sowie eine Investition durchführen.
      </div>
      <div>
        Falls Du als Affiliate Partner aktiv werden möchtest, bietet Dir
        <b> investview.de</b> die Möglichkeit, Teil des ePartnerprogramms zu
        werden und die verschiedenen Werbeanzeigen der jeweiligen Anbieter mit
        einem Reflink zu bewerben. Registriere Dich hierfür einfach auf
        <b> investview.de</b> über den entsprechenden Button und profitiere als
        Affiliate Partner von einem attraktiven Vergütungsmodell.
      </div>
    </div>
  );
}
