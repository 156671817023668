import Button from "./Button";
import Divider from "./Divider";
import { FaStore } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";

export default function HowItWorks() {
  return (
    <div>
      <div className="bg-gray-100 w-full flex flex-col justify-center items-center mt-4">
        <Divider text="So funktioniert's" />
        <div className="pl-8 pr-8 pb-8 pt-4 md:grid grid-cols-4 gap-4 flex flex-col gap-8 max-w-7xl justify-start md:items-start items-center">
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaStore size="3rem" color="#165272" />
            </div>
            <div className="font-bold">1. Anlagen durchstöbern</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaSearch size="3rem" color="#165272" />
            </div>
            <div className="font-bold">2. Anlage prüfen</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaEdit size="3rem" color="#165272" />
            </div>
            <div className="font-bold">3. Kapitalanlage zeichnen</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <FaChartLine size="3rem" color="#165272" />
            </div>
            <div className="font-bold">
              4. Von attraktiven Renditen profitieren
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col gap-6 ml-10 mr-10 mt-4 max-w-[65rem]">
          <div className="flex flex-col gap-4">
            <Divider text="Schritt 1: Anlagen durchstöbern" />
            <div>
              Willkommen beim Durchstöbern unseres Store-Anlagenmarktplatz! Hier
              findest Du eine Vielzahl von Werbea nzeigen aus verschiedenen
              Kategorien wie Start-Ups, Energie, Immobilien und KMUs. Wir legen
              großen Wert darauf, dass alle von uns gelisteten Anbieter den
              aufsichtsrechtlich regulierten Maßstäben gerecht werden.
            </div>
            <div>
              <ul>
                <li>
                  Wenn Du Dich für ein bestimmtes Projekt interessierst, klicke
                  einfach auf die entsprechende Anzeige. Dadurch verlässt Du
                  unseren Werbemarktplatz und wirst direkt zur Website des
                  Anbieters weitergeleitet. Hier findest Du weitere Details zu
                  dem Anbieter und seinen Projekten.
                </li>
                <li>
                  Falls Du als Affiliate Partner aktiv werden möchtest, bietet
                  Dir der Anlagen Marktplatz die Möglichkeit, Teil des
                  ePartnerprogramms zu werden und die verschiedenen
                  Werbeanzeigen mit einem Reflink zu bewerben. Registriere Dich
                  hierfür einfach über den entsprechenden Button und profitiere
                  als Affiliate Partner von einem attraktiven Vergütungsmodell.
                </li>
              </ul>
            </div>
            <div className="flex justify-center">
              <Button
                onClick={() =>
                  window.open(
                    "https://www.investormarket.de/epartnerprogramm/",
                    "_blank"
                  )
                }
              >
                Jetzt registrieren
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Divider text="Schritt 2: Anlage prüfen" />
            <div>
              Bevor Du jedoch auf der jeweiligen Anbieterseite investierst, ist
              es wichtig, dass Du Dich ausführlich über das Projekt informierst
              und eine fundierte Entscheidung triffst. Hier sind einige Tipps,
              wie Du die Anlage prüfen kannst:
            </div>
            <div>
              <ul>
                <li>
                  Auf der Startseite des jeweiligen Anbieters findest Du sein
                  aktuelle Anlagenangebot und über weitere Informationen hast Du
                  die Möglichkeit über die Projektprofilseite des jeweiligen
                  Angebots Dich über das Geschäftsmodell, den Markt und das
                  Beteiligungsangebot näher zu informieren. So erhältst Du einen
                  umfassenden Überblick über das Projekt und kannst entscheiden,
                  ob es für Dich geeignet ist.
                </li>
                <li>
                  Wirf einen Blick auf die umfangreiche FAQ-Zusammenstellung.
                  Dort findest Du Antworten auf häufig gestellte Fragen und
                  kannst weitere Einblicke in das Projekt gewinnen.
                </li>
                <li>
                  Nimm Dir Zeit, um über Deine Investitionshöhe und den
                  Zeitpunkt Deiner Zeichnung nachzudenken. Eine gut durchdachte
                  Entscheidung ist der Schlüssel zu einer erfolgreichen
                  Investition.
                </li>
              </ul>
            </div>
            <div>
              Wenn Du Dich für ein Private Placement des jeweiligen Anbieters
              interessierst, musst Du Dich lediglich vorher registrieren, um mit
              Hilfe eines Dir zugeschickten Codes auf die Projektprofilseite
              gelangen zu können und alle relevanten Daten und Dokumente
              bezüglich dieses Angebots einsehen zu können. Hierbei ist es
              lediglich den ersten 149 interessierten Anlegern pro EU-Land
              möglich das Private Placement einzusehen und zu zeichnen.
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Divider text="Schritt 3: Kapitalanlage zeichnen" />
            <div>
              <ul>
                <li>
                  Sobald Du Dich für eine Investition entschieden hast, klicke
                  auf der Projektprofilseite des gewünschten Angebots auf "Jetzt
                  zeichnen".
                </li>
                <li>
                  Im weiteren Registrierungsprozess wird der Anbieter Dich
                  bitten, Deinen Namen, Deine Meldeadresse sowie Angaben zu
                  Deinem Geburtsdatum, Deiner Steuernummer und Deinen Kontodaten
                  für die Zahlung anzugeben.
                </li>
                <li>
                  Wenn Du noch kein Wallet zur Verwaltung Deiner Tokens erstellt
                  hast, kannst Du über die Web-Extension MetaMask ein eigenes
                  Wallet erstellen. Eine genaue Anleitung dazu findest Du auf
                  der So funktioniert’s Seite des Anbieters.
                </li>
                <li>
                  Bitte überprüfe Deine Daten auf Richtigkeit und
                  Vollständigkeit und bestätige Deine Zeichnung verbindlich. Du
                  hast gegenüber dem Anbieter nach Deiner Zeichnung ein
                  14-tägiges Widerrufsrecht. Zusätzlich erhältst Du alle
                  Verträge und Formulare von dem Anbieter per E-Mail. Diese
                  können auch in Deinem Log-in Bereich des jeweiligen Anbieters
                  eingesehen werden.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Divider text="Schritt 4: Von attraktiven Renditen profitieren" />
            <div>
              Den Überblick über Deine Kapitalanlage beh ältst Du in Deinem
              persönlichen Online-Bereich des jeweiligen Anbieters. Dort kannst
              Du Daten ändern und alle wichtigen Dokumente und Verträge
              herunterladen. Profitiere von Deiner getätigten Kapitalanlage.
            </div>
            <div>Wir wünschen Dir viel Erfolg bei Deiner Investition!</div>
          </div>
        </div>
      </div>
    </div>
  );
}
