import axios from "axios";

export async function getProducts() {
  return axios
    .get("/product/marketplace-products")
    .then((response) => {
      return response.data.product;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}
