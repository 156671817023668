import axios from "axios";
import { BASE_URL } from "../constants";
// import { API_STATUS } from "../consts/api";
// import { getCookie } from "../utils/cookieUtils";

export const API_STATUS = {
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

const AXIOS_TIME_OUT = 35000;

export default function configureAxios() {
  //Configure Default base URL for all requests
  axios.defaults.baseURL = BASE_URL;

  //Set the time out to be 35 seconds
  axios.defaults.timeout = AXIOS_TIME_OUT;

  //Send every request with an access token attached in the headers
  // axios.interceptors.request.use((config) => addAccessTokenToHeader(config));

  axios.interceptors.response.use(
    (response) => handleResponse(response),
    (error) =>
      handleResponse({
        data: error?.response?.data,
        status: error?.response?.status,
      })
  );
}

async function handleResponse(response) {
  if (response.status >= 200 && response.status < 400)
    return {
      status: response.status,
      data: response.data,
      result: API_STATUS.SUCCESS,
    };
  else {
    console.error("API call failed with error: " + response.data.error);
    return {
      status: response.status,
      data: response.data,
      result: API_STATUS.FAILURE,
    };
  }
}
