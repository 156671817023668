import Divider from "./Divider";

export default function AboutUs() {
  return (
    <div>
      <Divider text="Über uns" />
      <div className="flex justify-center mt-6">
        <div className="flex flex-col gap-4 ml-10 mr-10 mt-4 max-w-[65rem]">
          <div className="text-[#165272] text-xl">
            <b>Investormarket - Community für Investoren und Emittenten</b>
          </div>
          <div>
            Als Vorausdenker und Innovationstreiber, haben wir mit
            investormarket.de und investview.de für Investoren und
            Werbe-/Affiliatepartner, ein mehrwertorientiertes Magazin, mit
            neutraler und transparenter Berichterstattung über den digitalen
            Investmentmarkt sowie einen Anlagen Marktplatz mit Werbeanzeigen für
            aufsichtsrechtlich regulierte Kapitalanlagen geschaffen. Wir
            arbeiten stetig daran, immer mehr Angebote rund um digitale
            Kapitalanlagen, durch externe Links zu verbinden und können dadurch
            einen ganzheitlichen Gesamtmarktüberblick bieten.
          </div>
          <div>
            <b>Mission</b>
          </div>
          <div>
            Mit unserer Community wollen wir sowohl Startups und
            mittelständische Unternehmen bei ihrer Kapitalisierung als auch
            Investoren und Privatanleger bei der Suche nach innovativen,
            nachhaltigen & ertragreichen Investitionsmöglichkeiten miteinander
            verknüpfen. So hast du als Investor dann die Möglichkeit, in aller
            Ruhe Dich in unserem Magazin unter der Rubrik Projekt- Branchenews
            über marktübergreifende Angebote zu informieren, als auch in unserem
            Store für Deine persönliche Lebenssituation die passende
            Kapitalanlage zu finden und von einer kostengünstigen Investition zu
            profitieren.
          </div>
          <div>
            <b>Vision</b>
          </div>
          <div>
            Wir stehen für Transparenz und Digitalisierung. Unsere Vision bei
            Investormarket ist es Anlegern den Aufbau einer besseren
            finanziellen Zukunft zu ermöglichen, indem wir umfassende und
            vertrauenswürdige Einblicke in den Markt und spannenden
            Crowdinvesting Projekten digital bieten
          </div>
          <div>
            Melde Dich bei investview.de an und abonniere noch heute Dein
            digitales Investormagazin über investormarket.de, um immer aktuell
            zu bleiben.
          </div>
          <div>
            Wir freuen uns, Dich in unserer investormarket.de Community begrüßen
            zu dürfen.
          </div>
        </div>
      </div>
    </div>
  );
}
