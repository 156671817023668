import { useNavigate } from "react-router-dom";

export default function Navbar({}) {
  let navigate = useNavigate();

  const TEXT_STYLE = `primary-color md:text-lg text-sm no-underline ml-2`;

  return (
    <>
      <nav className="flex text-center items-center justify-between pl-6 pr-6 pt-4 md:flex-row flex-col">
        <div
          className="mb-2 sm:mb-0 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img
            className="object-fit h-24"
            src={
              "https://smartefunding.s3.eu-central-1.amazonaws.com/static/Investview-logo.png"
            }
            alt="logo"
          />
        </div>
        <div className="flex md:gap-8 gap-2 items-center">
          <a href="/how-it-works" className={TEXT_STYLE}>
            So funktionierts
          </a>
          <a href="/e-partner" className={TEXT_STYLE}>
            ePartnerprogramm
          </a>
          <a href="/about-us" className={TEXT_STYLE}>
            Über Uns
          </a>
        </div>
      </nav>
    </>
  );
}
