import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import EPartner from "./components/EPartner";
import HowItWorks from "./components/HowItWorks";
import AboutUs from "./components/AboutUs";

export default function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col gap-4 content-wrap page-container">
        <Navbar />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/e-partner" element={<EPartner />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}
