import Link from "./Link";
import { useNavigate } from "react-router-dom";
import ExternalLink from "./ExternalLink";

const HEADER_STYLE = "text-white text-base font-bold mb-4";
const TEXT_SECTION_STYLE = "flex flex-col gap-2";

export default function Footer() {
  let navigate = useNavigate();

  return (
    <div className="w-100%">
      <div className="bg-[#262626] flex justify-around p-8 flex-col md:flex-row gap-4">
        <div>
          <div className={HEADER_STYLE}>Unternehmen</div>
          <div className={TEXT_SECTION_STYLE}>
            <Link
              text="So funktionierts"
              onClick={() => navigate("how-it-works")}
            />
            <Link text="Über uns" onClick={() => navigate("about-us")} />
          </div>
        </div>
        <div>
          <div className={HEADER_STYLE}>Rechtliches</div>
          <div className={TEXT_SECTION_STYLE}>
            <ExternalLink
              text="Allgemeine Geschäftsbedingungen.pdf (AGB)"
              url={
                "https://smartefunding.s3.eu-central-1.amazonaws.com/static/Allgemeine+Gescha%CC%88ftsbedingungen-investview.pdf"
              }
            />
            <ExternalLink
              text="Datenschutzerklärung.pdf (Datenschutz) "
              url={
                "https://smartefunding.s3.eu-central-1.amazonaws.com/static/Datenschutzerkla%CC%88rung-investview.pdf"
              }
            />
            <ExternalLink
              text="Impressum.pdf"
              url={
                "https://smartefunding.s3.eu-central-1.amazonaws.com/static/Impressum-investview.pdf"
              }
            />
          </div>
        </div>
        <div>
          <div className={HEADER_STYLE}>Kontakt</div>
          <div className={`${TEXT_SECTION_STYLE} text-gray-400`}>
            <div>eprogo GmbH</div>
            <div>Friedrichstr. 17, </div>
            <div>D-41061 Mönchengladbach </div>
            <div>Telefon: +49 2161 6969 4010 </div>
            <div>
              E-mail:{" "}
              <a href="mailto:kontakt@investormarket.de">
                kontakt@investormarket.de
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-white text-center">Powered by:</div>
          <img
            className="w-60"
            src="https://smartefunding.s3.eu-central-1.amazonaws.com/static/eprogo-white.png"
            alt="logo"
          />
        </div>
      </div>
      <div className="bg-[#222222]">
        <div className="text-gray-200 font-bold p-4">
          © 2024 eprogo - Alle Rechte vorbehalten
        </div>
      </div>
    </div>
  );
}
